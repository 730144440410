<template>
  <v-container class="white fill-height">
    <v-row justify="center" no-gutters>
      <v-col cols="12" md="6" class="d-flex align-center">
        <v-container>
          <v-row>
            <v-col>
              <span class="text-h4">{{ $trans("page_not_found") }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <p v-html="$trans('go_back_to_main_page')" />
            </v-col>
          </v-row>
          <v-row class="mt-6">
            <v-col class="d-flex align-center flex-wrap">
              <v-btn outlined x-large to="/">
                {{ $trans("main_page_button_title") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col class="d-none d-md-flex align-center justify-center" cols="6">
        <v-img
          :src="require('@/assets/login_illu.svg')"
          class="ma-2"
          contain
          max-width="400"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "NotFound",
  props: {
    code: {
      type: String,
      default: null,
    },
  },
  methods: {
    reload() {
      location.reload();
    },
  },
};
</script>

<style lang="scss"></style>
